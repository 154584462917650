import React, { useState } from 'react';
import './App.css';
import Login from './Login';
import ZarzadzajInspiracje from './ZarzadzajInspiracje';
import ZarzadzajProjekty from './ZarzadzajProjekty';
import StorageInfo from './StorageInfo';
import BlogHandler from './BlogHandler';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLoginSuccess = () => {
    setLoggedIn(true);
  };

  return (
    <div className="App">
      {!loggedIn ? (
        <Login onLoginSuccess={handleLoginSuccess} />
      ) : (
        <div className="app-container">
          <div className="sidebar">
            <StorageInfo />
          </div>
          <div className="content">
            <ZarzadzajInspiracje />
            <ZarzadzajProjekty />
            <BlogHandler />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
