import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Chip,
  Paper,
  Grid,
  IconButton,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import axios from 'axios';

Modal.setAppElement('#root');

function BlogHandler() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [newBlog, setNewBlog] = useState({
    title: '',
    pubdate: '',
    author: '',
    image: '',
    tags: '',
    slug: '',
    description: '',
    question: '',
    body: '',
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [isBuilding, setIsBuilding] = useState(false);
  const [buildStatus, setBuildStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const openModal = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    setNewBlog({
      title: '',
      pubdate: '',
      author: '',
      image: '',
      tags: '',
      slug: '',
      description: '',
      question: '',
      body: '',
    });
    setImagePreview(null);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBlog({ ...newBlog, [name]: value });
  };

  const handleAddBlog = async () => {
    const token = localStorage.getItem('token'); // Use the correct key here
    if (newBlog.title && newBlog.body) {
      const formattedBlog = {
        ...newBlog,
        tags: newBlog.tags.split(',').map(tag => tag.trim()),
      };
      
      try {
        const response = await fetch('/api/add_blog', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Attach the token
          },
          body: JSON.stringify(formattedBlog)
        });
  
        if (response.ok) {
          const savedBlog = await response.json();
          setBlogs([...blogs, savedBlog]);
          closeModal();
          alert('Blog added successfully!');
        } else {
          console.error('Failed to save blog', response.statusText);
          alert('Failed to add blog. Please try again.');
        }
      } catch (error) {
        console.error('Error while saving blog:', error);
        alert('An error occurred while saving the blog.');
      }
    }
  };

  const handleBuild = async () => {
    try {
      const token = localStorage.getItem('token'); // Consistently use 'token' here
      const response = await axios.post(`https://admin.wallface.pl/api/build`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 202) {
        setIsBuilding(true);
        setBuildStatus('Rozpoczeto budowanie...');
      } else {
        setErrorMessage('Nie udalo sie zastosowac zmian na stronie gl�wnej.');
      }
    } catch (error) {
      setErrorMessage(`Nie udalo sie zastosowac zmian na stronie gl�wnej. Blad: ${error.message}`);
      console.error('Error during the change application', error);
    }
  };

  useEffect(() => {
    let interval;
    if (isBuilding) {
      interval = setInterval(async () => {
        try {
          const statusResponse = await axios.get(`https://admin.wallface.pl/api/build/status`);
          setBuildStatus(statusResponse.data.message);
          if (statusResponse.data.status !== 'running') {
            setIsBuilding(false);
            if (statusResponse.data.status === 'success') {
              setSuccessMessage('Zmiany zostaly pomyslnie zastosowane na stronie gl�wnej.');
            } else {
              setErrorMessage(statusResponse.data.message);
            }
          }
        } catch (error) {
          setErrorMessage(`Blad podczas sprawdzania statusu budowania: ${error.message}`);
          setIsBuilding(false);
        }
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [isBuilding]);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const token = localStorage.getItem('token'); // Be consistent with token key

    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await axios.post('https://admin.wallface.pl/api/upload_image', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          const data = response.data;
          setNewBlog({ ...newBlog, image: data.imageUrl });
          setImagePreview(URL.createObjectURL(file));
          alert('Image uploaded successfully!');
        } else {
          console.error('Failed to upload image', response.statusText);
          alert('Failed to upload image. Please try again.');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('An error occurred while uploading the image.');
      }
    }
  };

  const renderMarkdownPreview = () => {
    const markdownContent = `
---
title: "${newBlog.title}"
pubdate: "${newBlog.pubdate}"
author: "${newBlog.author}"
image: "${newBlog.image}"
tags: [${newBlog.tags.split(',').map(tag => `"${tag.trim()}"`).join(', ')}]
slug: "${newBlog.slug}"
description: "${newBlog.description}"
question: "${newBlog.question}"
---


${newBlog.body}
    `;
    return <ReactMarkdown>{markdownContent}</ReactMarkdown>;
  };

  const isFormValid = newBlog.title && newBlog.body && newBlog.slug;

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Lista Blog�w
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={openModal}
        sx={{ marginBottom: 2 }}
      >
        Dodaj Blog
      </Button>
      <Button
        onClick={handleBuild}
        className="build-button"
        disabled={isBuilding}
      >
        Zastosuj zmiany na stronie gl�wnej
      </Button>
      {buildStatus && <Typography>{buildStatus}</Typography>}
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      {successMessage && <Typography color="primary">{successMessage}</Typography>}

      {blogs.map((blog) => (
        <Paper key={blog.id} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="h5">{blog.title}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {blog.pubdate} - {blog.author}
          </Typography>
          {blog.image && <img src={blog.image} alt={blog.title} style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }} />}
          <Typography variant="body2">{blog.description}</Typography>
          <Box sx={{ marginTop: 1 }}>
            {blog.tags.map((tag, index) => (
              <Chip key={index} label={tag} sx={{ marginRight: 0.5 }} />
            ))}
          </Box>
        </Paper>
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Dodaj Nowy Blog"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            border: 'none',
            borderRadius: '8px',
            overflow: 'hidden',
            maxWidth: '600px',
            width: '100%',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <Box sx={{ padding: 3 }}>
          <Typography variant="h6" gutterBottom>
            Dodaj Nowy Blog
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Tytul"
                name="title"
                value={newBlog.title}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Data publikacji"
                name="pubdate"
                type="date"
                value={newBlog.pubdate}
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Autor"
                name="author"
                value={newBlog.author}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Obraz
              </Typography>
              <Box display="flex" alignItems="center">
                <IconButton component="label">
                  <ImageIcon />
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                  />
                </IconButton>
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxHeight: '100px', marginLeft: '16px' }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Tagi (oddzielone przecinkami)"
                name="tags"
                value={newBlog.tags}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Slug"
                name="slug"
                value={newBlog.slug}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Opis"
                name="description"
                value={newBlog.description}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Pytanie"
                name="question"
                value={newBlog.question}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Tresc"
                name="body"
                value={newBlog.body}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="subtitle1">Podglad:</Typography>
            <Paper
              variant="outlined"
              sx={{
                padding: 2,
                maxHeight: '200px',
                overflowY: 'auto',
                backgroundColor: '#f9f9f9',
              }}
            >
              {renderMarkdownPreview()}
            </Paper>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            <Button variant="contained" color="primary" onClick={handleAddBlog} disabled={!isFormValid}>
              Dodaj Blog
            </Button>
            <Button variant="outlined" color="secondary" onClick={closeModal}>
              Anuluj
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
}

export default BlogHandler;
