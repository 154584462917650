// StorageInfo.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PieChart } from '@mui/x-charts/PieChart'; 

function StorageInfo() {
  const [storageInfo, setStorageInfo] = useState({ total: 0, used: 0, free: 0 });

  useEffect(() => {
    const fetchStorageInfo = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('https://admin.wallface.pl/api/storage', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStorageInfo(response.data);
      } catch (error) {
        console.error('Nie udało się pobrać informacji o przestrzeni dyskowej', error);
      }
    };

    fetchStorageInfo();
  }, []);

  const pieData = [
    { id: 'Używana przestrzeń', value: storageInfo.used, label: 'Używana przestrzeń', color: '#4e73df' },
    { id: 'Wolna przestrzeń', value: storageInfo.free, label: 'Wolna przestrzeń', color: '#1cc88a' }
  ];

  return (
    <div className="storage-info">
      <h2>Informacje o przestrzeni dyskowej</h2>
      <PieChart
        series={[{ data: pieData }]}
        width={280}
        height={280}
        innerRadius={0.7}
        outerRadius={1}
        colors={pieData.map(data => data.color)}
        tooltip={({ id, value, label }) => `${label}: ${value} GB`} // Tooltip for hover
        slotProps={{legend: {hidden: true}}}
      />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        {pieData.map((item, index) => (
          <div key={index} style={{ textAlign: 'center', margin: '0 10px' }}>
            <div style={{ backgroundColor: item.color, width: '20px', height: '20px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }}></div>
            {item.label}: {item.value} GB
          </div>
        ))}
      </div>
      <div style={{ marginTop: '10px', textAlign: 'center' }}>
        <p><strong>Całkowita przestrzeń:</strong> {storageInfo.total} GB</p>
        <p><strong>Używana przestrzeń:</strong> {storageInfo.used} GB</p>
        <p><strong>Wolna przestrzeń:</strong> {storageInfo.free} GB</p>
      </div>
    </div>
  );
}

export default StorageInfo;
