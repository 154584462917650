import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

function ZarzadzajInspiracje() {
  const category = 'inspiracje';
  const [file, setFile] = useState(null);
  const [fileNames, setFileNames] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState(new Set());
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isBuilding, setIsBuilding] = useState(false);
  const [buildStatus, setBuildStatus] = useState('');

  const fetchImages = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://admin.wallface.pl/api/images/${category}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (Array.isArray(response.data)) {
        setImages(response.data);
      } else {
        throw new Error('Response does not contain an array');
      }
    } catch (error) {
      setErrorMessage(`Nie udało się pobrać obrazów. Upewnij się, że jesteś zalogowany. Błąd: ${error.message}`);
      console.error('Failed to fetch images', error);
    }
  }, [category]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;

    console.log('Selected files:', selectedFiles);

    setFile(selectedFiles);

    const names = Array.from(selectedFiles).map((file) => file.name);
    setFileNames(names);

    console.log('File names:', names);
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    if (!file || file.length === 0) {
      setErrorMessage('Proszę wybrać pliki do przesłania.');
      return;
    }

    const formData = new FormData();
    Array.from(file).forEach((f) => {
      formData.append('file', f);
    });

    try {
      const token = localStorage.getItem('token');
      await axios.post(`https://admin.wallface.pl/api/upload/${category}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccessMessage('Przesyłanie zakończone sukcesem');
      setErrorMessage('');
      setFile(null);
      setFileNames([]);

      await fetchImages();
    } catch (error) {
      setErrorMessage(`Przesyłanie nie powiodło się. Spróbuj ponownie. Błąd: ${error.message}`);
      setSuccessMessage('');
      console.error('Upload failed', error);
    }
  };

  const handleDelete = async () => {
    const confirmed = window.confirm('Czy na pewno chcesz usunąć wybrane obrazy?');
    if (!confirmed) return;

    try {
      const token = localStorage.getItem('token');
      for (let image of selectedImages) {
        await axios.delete(`https://admin.wallface.pl/api/delete/${category}/${image}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      setSuccessMessage('Wybrane obrazy zostały pomyślnie usunięte');
      setErrorMessage('');

      setImages(images.filter((image) => !selectedImages.has(image)));
      setSelectedImages(new Set());
    } catch (error) {
      setErrorMessage(`Nie udało się usunąć obrazów. Spróbuj ponownie. Błąd: ${error.message}`);
      setSuccessMessage('');
      console.error('Failed to delete images', error);
    }
  };

  const toggleImageSelection = (image) => {
    setSelectedImages((prevSelected) => {
      const newSelection = new Set(prevSelected);
      if (newSelection.has(image)) {
        newSelection.delete(image);
      } else {
        newSelection.add(image);
      }
      return newSelection;
    });
  };

  const handleBuild = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`https://admin.wallface.pl/api/build`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setIsBuilding(true);
        setBuildStatus('Rozpoczęto budowanie...');
      } else {
        setErrorMessage('Nie udało się zastosować zmian na stronie głównej.');
      }
    } catch (error) {
      setErrorMessage(`Nie udało się zastosować zmian na stronie głównej. Błąd: ${error.message}`);
      console.error('Error during the change application', error);
    }
  };

  useEffect(() => {
    let interval;
    if (isBuilding) {
      interval = setInterval(async () => {
        try {
          const statusResponse = await axios.get(`https://admin.wallface.pl/api/build/status`);
          setBuildStatus(statusResponse.data.message);
          if (statusResponse.data.status !== 'running') {
            setIsBuilding(false);
            if (statusResponse.data.status === 'success') {
              setSuccessMessage('Zmiany zostały pomyślnie zastosowane na stronie głównej.');
            } else {
              setErrorMessage(statusResponse.data.message);
            }
          }
        } catch (error) {
          setErrorMessage(`Błąd podczas sprawdzania statusu budowania: ${error.message}`);
          setIsBuilding(false);
        }
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [isBuilding]);

  return (
    <div className="manage-images-container">
      <h1>Zarządzaj Inspiracje</h1>
      <div className="messages">
        {errorMessage && <p className="error">{errorMessage}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
      </div>
      <form onSubmit={handleUpload}>
        <label htmlFor="file-upload-inspiracje" className="file-label">
          Wybierz pliki
          <input
            id="file-upload-inspiracje"
            type="file"
            name="file-upload-inspiracje"
            onChange={handleFileChange}
            multiple
          />
        </label>
        <div className="file-names">
          {fileNames.map((name, index) => (
            <div key={index}>{name}</div>
          ))}
        </div>
        <button type="submit">Prześlij</button>
      </form>
      <div className="actions">
        <button
          onClick={handleDelete}
          disabled={selectedImages.size === 0}
          className="delete-selected-button"
        >
          Usuń wybrane obrazy
        </button>
        <button
          onClick={handleBuild}
          className="build-button"
          disabled={isBuilding}
        >
          Zastosuj zmiany na stronie głównej
        </button>
      </div>
      {isBuilding && (
        <div className="loading">
          <p>{buildStatus}</p>
          <div className="loader"></div>
        </div>
      )}
      <h2>Obecne obrazy</h2>
      <div className="image-grid">
        {images.map((image) => (
          <div
            className={`image-item ${selectedImages.has(image) ? 'selected' : ''}`}
            key={image}
            onClick={() => toggleImageSelection(image)}
          >
            <img
              src={`https://admin.wallface.pl/uploads/${category}/${image}`}
              alt={image}
              className="image"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ZarzadzajInspiracje;
